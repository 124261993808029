/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import STATUSCODE from '@common/constant/status';
import { Card, Col, Form, Input, message } from 'antd';
import PropTypes from 'prop-types';
import userApi from '@services/userApi';
import { t } from 'i18next';
import ComButton from '@components/common/ComButton';
import ComUpload from '@components/common/ComUpload';
import {
  validateMax,
  validateNoJapaneseFullWidth,
  validateRange,
} from '@common/utils/validate';
import TextField from '@components/common/ComTextField';
import SelectField from '@components/common/ComSelectField';
import departmentApi from '@services/department';
import companyApi from '@services/company';
import { useSelector } from 'react-redux';
import { AccountContext } from '.';

function AccountForm({ closeModal, initialValues, isModalOpen }) {
  const [form] = Form.useForm();
  const avt = Form.useWatch('avatar', form);
  const watchDepartment = Form.useWatch('department_id', form);
  const watchCompany = Form.useWatch('company_id', form);
  const dataLogin = useSelector((state) => state?.auth?.dataLocalstorage);
  const [fileList, setFileList] = useState([]);

  const [dataAccount, setDataAccount] = useState();
  const [listCompany, setListCompany] = useState();
  const [listDepartment, setListDepartment] = useState();
  const [isEditMode, setIsEditMode] = useState(true);
  const { showDataAccount, listUser, setLoading, loading } =
    useContext(AccountContext);
  const companyListOpton = useMemo(
    () =>
      listCompany?.map((e) => ({
        label: e?.name,
        value: e?.id,
        ...e,
      })),
    [listCompany],
  );
  const filterDepartments = listDepartment?.filter(
    (x) => x?.company_id === watchCompany,
  );
  const departmentListOpton = useMemo(
    () =>
      dataLogin?.user_type_id === 1
        ? filterDepartments?.map((e) => ({
            label: e?.name,
            value: Number(e?.id),
            ...e,
          }))
        : listDepartment?.map((e) => ({
            label: e?.name,
            value: Number(e?.id),
            ...e,
          })),
    [filterDepartments],
  );
  const getDataAccount = async () => {
    const data = await userApi.getMe();
    setDataAccount(data?.data);
    if (dataLogin?.user_type_id !== 1) {
      setListCompany([data?.data?.company]);
    }
  };
  const onFinish = async (values) => {
    setLoading(true);
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] =
        typeof values[key] === 'string' ? values[key].trim() : values[key];
      return acc;
    }, {});
    const { avatar, password, ...data } = trimmedValues;
    const params = password
      ? {
          ...data,
          invalid: false,
          password,
        }
      : { ...data, invalid: false };
    const checkData = watchDepartment
      ? params
      : { ...params, department_id: null };
    try {
      window.scrollTo(0, document.body.scrollHeight);
      setLoading(true);
      const res = await userApi.UpdateMe(checkData);
      if (res?.statusCode === STATUSCODE.SUCCESS) {
        const formData = new FormData();
        if (avatar[0]?.originFileObj) {
          formData.append('file', avatar[0]?.originFileObj);

          if (dataLogin?.user_type_id === 1) {
            await userApi.uploadImage(res?.data?.id, formData);
          } else {
            await userApi.uploadAvatar(formData);
          }
        }
        if (avatar?.length === 0) {
          if (dataLogin?.user_type_id === 1) {
            await userApi.deleteAvatar(res?.data?.id);
          } else {
            await userApi.deleteAvatarMe();
          }
        }
        message.success(t('user.message.mesageEditSuccess'));
        form.resetFields();
        form.setFieldValue('avatar', []);
      }
      setLoading(false);
      closeModal();
    } catch (error) {
      setLoading(false);
      if (error?.response.data.statusCode === STATUSCODE.BAD_REQUEST) {
        message.error('ユーザーを更新できませんでした');
      }
      if (error?.response.data.statusCode === STATUSCODE.SERVER_ERROR) {
        message.error(t('user.message.mesageEditFail'));
      }
    } finally {
      setFileList([]);
      setLoading(false);
    }
  };
  const handleFormValuesChange = (changedValues, allValues) => {
    const fieldActions = {
      password: () => form.validateFields(['re_password']),
      company_id: () => form.setFieldValue('department_id', ''),
    };

    Object.keys(changedValues).forEach((key) => {
      if (key in fieldActions) {
        fieldActions[key]();
      }
    });
  };
  useEffect(() => {
    getDataAccount();
  }, [listUser, isModalOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const company = companyApi.getListCompany();
        const department = departmentApi.getListDepartment();
        const [result1, result2] = await Promise.all([company, department]);
        setListCompany(result1.data.data);
        setListDepartment(result2.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchDataUser = async () => {
      try {
        const department = await departmentApi.getListDropdown(
          Number(dataAccount?.company_id),
        );
        setListDepartment(department?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (dataLogin?.user_type_id === 1) {
      fetchData();
    } else if (dataAccount) {
      fetchDataUser();
    }
  }, [dataAccount, isModalOpen]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(listUser);
  }, [listUser]);
  const configTextField = {
    labelCol: {
      xs: { span: 14 },
      sm: { span: 10 },
      md: { span: 10 },
      lg: { span: 8 },
      xl: { span: 6 },
      xxl: { span: 5 },
    },
    labelAlign: 'left',
  };
  const roleOptions = [
    { label: 'Admin', value: 1 },
    { label: 'User', value: 2 },
  ];
  return (
    <div>
      <Card className="edit-account-form">
        <Form
          name="tab2-form"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          colon={false}
          className="user-form"
          validateTrigger="onChange"
          onValuesChange={handleFormValuesChange}
        >
          <TextField
            {...configTextField}
            label={t('dashboard.tab2.form.userId')}
            name="user_id"
            labelCol={{ span: 10 }}
            disabled
            rules={[
              {
                type: 'email',
                message: t('user.message.requiredEmail'),
              },
              {
                validator: (rule, value, callback) =>
                  validateMax(
                    rule,
                    value,
                    callback,
                    50,
                    t('dashboard.tab2.form.userId'),
                  ),
              },
            ]}
          />
          <Form.Item
            {...configTextField}
            label="パスワード"
            name="password"
            labelCol={{ span: 10 }}
            rules={[
              {
                validator: (rule, value, callback) =>
                  validateRange(
                    rule,
                    value,
                    callback,
                    6,
                    30,
                    t('dashboard.tab2.form.password'),
                    isEditMode,
                  ),
              },
            ]}
          >
            <Input.Password
              autoComplete="new-password"
              onCopy={(e) => {
                e.preventDefault();
                e.clipboardData.setData('text/plain', '');
              }}
            />
          </Form.Item>
          <Form.Item
            {...configTextField}
            label="パスワード（再）"
            name="re_password"
            labelCol={{ span: 10 }}
            rules={[
              {
                type: 'password',
                message: t('user.message.password'),
              },
              {
                validator(rule, value, callback) {
                  const password = form.getFieldValue('password');
                  if (password && !value) {
                    callback('パスワード（再）は必須入力です。');
                  } else if (value?.length < 6 || value?.length > 30) {
                    validateRange(
                      rule,
                      value,
                      callback,
                      6,
                      30,
                      t('dashboard.tab2.form.password'),
                      isEditMode,
                    );
                  } else if (password !== value && password) {
                    callback(
                      'パスワードが一致していません。再入力してください。',
                    );
                  } else {
                    callback();
                  }
                },
              },
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <TextField
            {...configTextField}
            label="氏名（英語表記・名姓)"
            name="username"
            labelCol={{ span: 10 }}
            placeholder={dataAccount?.username}
            rules={[
              {
                validator: (rule, value, callback) =>
                  validateMax(
                    rule,
                    value,
                    callback,
                    50,
                    '氏名（英語表記・名姓)',
                    'fullWidth',
                  ),
              },
              {
                validator: (rule, value, callback) =>
                  validateNoJapaneseFullWidth(rule, value, callback),
              },
            ]}
          />
          <SelectField
            {...configTextField}
            labelCol={{ span: 10 }}
            label={t('dashboard.tab2.form.companyName')}
            name="company_id"
            options={companyListOpton}
            rules={[
              { required: true, message: t('user.message.needpermission') },
            ]}
            disabled={initialValues?.user_type_id === 2}
          />
          <SelectField
            {...configTextField}
            labelCol={{ span: 10 }}
            label={t('dashboard.tab2.form.department')}
            name="department_id"
            options={departmentListOpton || []}
            disabled={!watchCompany}
          />
          <TextField
            {...configTextField}
            label={t('dashboard.tab2.form.initial')}
            name="initial"
            placeholder={dataAccount?.initial}
            rules={[
              {
                validator: (rule, value, callback) =>
                  validateMax(
                    rule,
                    value,
                    callback,
                    2,
                    t('dashboard.tab2.form.initial'),
                  ),
              },
              {
                validator: (rule, value, callback) =>
                  validateNoJapaneseFullWidth(rule, value, callback),
              },
            ]}
          />
          <SelectField
            {...configTextField}
            labelCol={{ span: 10 }}
            label={t('dashboard.tab2.form.role')}
            name="user_type_id"
            options={roleOptions || []}
            rules={[
              { required: true, message: t('user.message.authorization') },
            ]}
            disabled={initialValues?.user_type_id === 2}
          />
          <ComUpload
            label="顔写真"
            name="avatar"
            fileList={avt || []}
            setFileList={setFileList}
            className="form-upload-user"
          />
          <div className="full-width button-group btn-submit">
            <ComButton
              disabled={loading}
              htmlType="submit"
              className="save-btn"
            >
              変更
            </ComButton>
          </div>
        </Form>
      </Card>
    </div>
  );
}
AccountForm.propTypes = {
  closeModal: PropTypes.func,
  initialValues: PropTypes.any,
  setUpdateSuccess: PropTypes.func,
  updateSuccess: PropTypes.bool,
  isModalOpen: PropTypes.bool,
};
export default AccountForm;
