import { CLIENT_ID } from '@common/config/endpoint';

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID || '',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['User.Read'],
};
